<template>
  <div class="charging-car-wrap">
    <container>
      <div class="charging-car">
        <BtnMenu />
        <div class="charging-switches">
          <div
            class="charging-switches__item"
            @click="$router.push('/navigator')"
          >
            Навигатор
          </div>
          <div class="charging-switches__item active">Где заряжаться</div>
        </div>
        <div class="charging-drop show">
          <AppInput
            class="charging-car__input"
            error="Заполните поле"
            idInput="charging-car-input"
            placeholder="Поиск городу, улице"
            @appInput="inputAppFunc"
          />
          <div class="map-legend">
            <div class="map-legend__item">
              <div class="map-legend__item-svg">
                <svg-icon height="32" name="map-legend-icon1" width="27" />
              </div>
              <span class="map-legend__item-text"
                >Быстрые зарядные станции, в том числе в ДЦ Porsche</span
              >
            </div>
            <div class="map-legend__item">
              <div class="map-legend__item-svg">
                <svg-icon height="32" name="map-legend-icon2" width="27" />
              </div>
              <span class="map-legend__item-text"
                >Зарядные станции в городе</span
              >
            </div>
          </div>
        </div>
        <div class="charging-drop-arrow" @click="chargingDrop">
          <svg-icon height="36" name="charging-arrow-up" width="80" />
        </div>
      </div>
    </container>
    <div id="charging-map" class="charging-map">
      <yandex-map
        :controls="['zoomControl']"
        :coords="center"
        :options="{ suppressMapOpenBlock: true, minZoom: 3, maxZoom: 18 }"
        :settings="settings"
        :use-object-manager="true"
        :zoom="zoomMap"
        @boundschange="chargeMap"
        @map-was-initialized="initMap"
      >
      </yandex-map>
      <div
        v-if="this.conterPlaceNum"
        class="charging-map__block-inf charging-counter"
      >
        <span class="charging-counter__title"
          >Количество зарядных станций в видимой области</span
        >
        <span class="charging-counter__num">{{ this.conterPlaceNum }}</span>
      </div>
      <div
        v-if="!this.conterPlaceNum"
        class="charging-map__block-inf PlugShare"
      >
        <div class="PlugShare__info-wrap">
          <div class="PlugShare__title-wrap">
            <img
              alt="PlugShare"
              class="PlugShare__title-img"
              src="@/assets/img/PlugShare.png"
            />
            <span class="PlugShare__title">PlugShare</span>
          </div>
          <div class="PlugShare__text">
            <p>
              Наиболее полная карта зарядных станций по&nbsp;всему миру
              в&nbsp;одном приложении с&nbsp;самой актуальной информацией
              и&nbsp;отзывами других водителей.
            </p>
          </div>
        </div>
        <div class="PlugShare__right">
          <div class="PlugShare__qrocde-wrap">
            <svg-icon
              v-if="!switchPlag"
              class="PlugShare__qrocde"
              height="120"
              name="qrcodeAndroid"
              width="120"
            />
            <svg-icon
              v-if="switchPlag"
              class="PlugShare__qrocde"
              height="120"
              name="qrcodeIOS"
              width="120"
            />
          </div>
          <div class="PlugShare-switch-wrap">
            <div class="PlugShare-switch">
              <span
                :class="{ active: switchPlag }"
                class="PlugShare-switch__item"
                @click="PlugShareSwitch(true)"
                >IOS</span
              >
              <span
                :class="{ active: !switchPlag }"
                class="PlugShare-switch__item"
                @click="PlugShareSwitch(false)"
                >Android</span
              >
            </div>
            <div class="PlugShare-descr">
              <p>Наведите камеру смартфона на QR-код</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Container from "../components/Container/Container.vue";
import BtnMenu from "../components/BtnMenu.vue";
import AppInput from "../components/AppInput.vue";
import SvgIcon from "../components/IconBase";
import { yandexMap } from "vue-yandex-maps";
import plagArray from "../plugCharge.json";

export default {
  components: {
    Container,
    BtnMenu,
    AppInput,
    SvgIcon,
    yandexMap,
  },
  data: () => {
    return {
      switchPlag: true,
      zoomMap: 10,
      settings: {
        apiKey: "330cf713-ad09-45f2-ba8e-1572de7b7131",
        lang: "ru_RU",
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
      },
      inputApp: "",
      center: [55.755819, 37.617644],
      conterPlaceNum: 0,
      objectManager: null,
    };
  },
  computed: {},
  methods: {
    chargingDrop() {
      let drop = document.querySelector(".charging-drop");
      drop.classList.toggle("show");
    },
    PlugShareSwitch(isSwitch) {
      this.switchPlag = isSwitch;
    },
    chargeMap({ originalEvent }) {
      this.conterPlaceNum = ymaps
        .geoQuery(this.objectManager.objects)
        .searchIntersect(originalEvent.map)
        .getLength();
    },
    inputAppFunc(val) {
      this.inputApp = val;
    },
    initMap(mapinfo) {
      this.center = [55.755819, 37.617655];
      let suggestView1 = new ymaps.SuggestView("charging-car-input", {
        results: 4,
      });

      this.objectManager = new ymaps.ObjectManager();
      mapinfo.geoObjects.add(this.objectManager);
      this.objectManager.add(plagArray);

      suggestView1.events.add("select", (e) => {
        let res = e.get("item").value;
        let myGeocoder = ymaps.geocode(res);
        myGeocoder.then((res) => {
          if (res) {
            document.activeElement.blur();
          }
          let bounds = res.geoObjects.get(0).properties.get("boundedBy");
          mapinfo.setBounds(bounds, {
            checkZoomRange: true,
          });
          mapinfo.setCenter(res.geoObjects.get(0).geometry.getCoordinates());
        });
      });
    },
  },
};
</script>

<style>
.customBalloon {
  max-width: 224px;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.02em;
  font-family: "PorscheNext";
  padding: 20px 40px 20px 20px;
  background: #60d2ba;
}

.customBalloon.blue {
  background: #3ec5fc;
}

.customBalloon__arrow {
  bottom: 20px;
  width: 2px;
  height: 9px;
  margin-left: 55px;
  background: #60d2ba;
}

.customBalloon__arrow.blue {
  background: #3ec5fc;
}

.customBalloon__line {
  background: white;
  width: 100%;
  height: 2px;
  margin: 10px 0;
}

.customBalloon__description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
}

.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon__close-button {
  background: url(../assets/icon/x.png) center no-repeat;
  height: 35px;
  width: 40px;
  opacity: 1;
}

.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon__layout {
  background: transparent;
}

.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon__content {
  background: transparent;
}

.ymaps-2-1-79-balloon-overlay .ymaps-2-1-79-balloon {
  box-shadow: none;
}

.ymaps-2-1-79-balloon
  .ymaps-2-1-79-balloon__close
  + .ymaps-2-1-79-balloon__content {
  margin: 0;
  padding: 0;
}

.ymaps-2-1-79-balloon-overlay
  .ymaps-2-1-79-balloon_to_top
  .ymaps-2-1-79-balloon__tail {
  transform: none;
  box-shadow: none;
  background: transparent;
}

.ymaps-2-1-79-balloon .ymaps-2-1-79-balloon__tail:after {
  display: none;
}

html,
body,
#app,
.charging-car-wrap {
  height: 100%;
}

.charging-car-wrap {
  display: flex;
  flex-direction: column;
}

.charging-car {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 100px;
}

.charging-map {
  display: flex;
  flex: 1;
  background-color: #ffffff;
  position: relative;
}

.charging-switches {
  display: flex;
}

.charging-switches__item {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.charging-switches__item.active {
  border-bottom: 2px solid #3ec5fc;
  padding-bottom: 4px;
}

.charging-switches__item + .charging-switches__item {
  margin-left: 40px;
}

.charging-car__input {
  margin-top: 39px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.charging-car__input .input {
  width: 100%;
}

.charging-drop {
  position: relative;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.3s, visibility 0.3s, opacity 0.3s;
}

.charging-drop.show {
  opacity: 1;
  visibility: visible;
  height: auto;
  max-height: 200px;
}

.map-legend {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
}

.map-legend__item {
  display: flex;
  align-items: center;
  max-width: 350px;
}

.map-legend__item + .map-legend__item {
  margin-left: 32px;
}

.map-legend__item-svg {
}

.map-legend__item-svg svg {
  display: block;
}

.map-legend__item-text {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 20px;
  width: 290px;
  min-width: 290px;
}

.charging-drop-arrow {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  cursor: pointer;
}

.charging-drop-arrow svg {
  display: block;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.charging-drop.show + .charging-drop-arrow svg {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.charging-counter {
  position: absolute;
  bottom: 48px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #000000;
  max-width: 488px;
  display: flex;
  flex-direction: column;
  padding: 36px;
}

.charging-counter__title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  display: block;
}

.charging-counter__num {
  display: block;
  margin-top: 14px;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 100%;
  color: #ffffff;
}

.PlugShare {
  position: absolute;
  bottom: 48px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #000000;
  max-width: 800px;
  display: flex;
  padding: 38px 30px;
}

.PlugShare__info-wrap {
  width: 50%;
}

.PlugShare__title-wrap {
  display: flex;
  align-items: center;
}

.PlugShare__title-img {
  display: block;
  width: 60px;
  height: 60px;
}

.PlugShare__title {
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 100%;
  color: #ffffff;
  margin-left: 20px;
}

.PlugShare__text {
  margin-top: 20px;
}

.PlugShare__text p {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-align: left;
}

.PlugShare__right {
  width: 50%;
  display: flex;
  align-items: center;
}

.PlugShare__qrocde-wrap {
  width: 120px;
  height: 120px;
  min-width: 120px;
}

.PlugShare__qrocde-wrap svg {
  display: block;
  width: 100%;
  height: 100%;
}

.PlugShare__qrocde {
}

.PlugShare-switch-wrap {
  margin-left: 31px;
}

.PlugShare-switch {
  display: flex;
}

.PlugShare-switch__item {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding-bottom: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.PlugShare-switch__item.active {
  border-bottom: 2px solid #3ec5fc;
}

.PlugShare-switch__item + .PlugShare-switch__item {
  margin-left: 40px;
}

.PlugShare-descr {
  margin-top: 20px;
}

.PlugShare-descr p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-align: left;
}

.ymap-container {
  width: 100%;
  height: 100%;
}

.ymaps-2-1-79-ground-pane {
  filter: grayscale(100%);
}

/* .ymap-class{
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
} */
.ymaps-2-1-79-map {
  width: 100% !important;
  height: 100% !important;
}

.charging-car__input .ymaps-2-1-79-search__suggest {
  background-color: #262626;
  border: none;
  margin-top: 5px;
}

.charging-car__input .ymaps-2-1-79-search__suggest:before {
  content: "";
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  width: 32px;
  background: linear-gradient(270deg, #262626 0%, rgba(38, 38, 38, 0) 100%);
}

.charging-car__input .ymaps-2-1-79-search__suggest-item {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 0;
  text-overflow: inherit;
  padding: 16px 10px 16px 24px;
}

.charging-car__input .ymaps-2-1-79-suggest-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.charging-car__input .ymaps-2-1-79-search__suggest {
  left: 0;
  right: 0;
}

.charging-car__input .ymaps-2-1-79-search__suggest-item_selected_yes {
  background: none;
}

.charging-car__input .ymaps-2-1-79-suggest-item-0 {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .map-legend__item {
    max-width: 255px !important;
  }
  .charging-car__input .ymaps-2-1-79-search__suggest-item {
    font-size: 16px;
    line-height: 150%;
    padding: 0;
  }

  .charging-car__input .ymaps-2-1-79-suggest-item {
    padding: 12px 10px 12px 18px;
  }

  .charging-car__input .ymaps-2-1-79-suggest-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .charging-car__input .ymaps-2-1-79-search__suggest:before {
    width: 26px;
  }

  .charging-car__input .ymaps-2-1-79-search__suggest {
    left: 0;
    right: 0;
  }

  .charging-car__input .ymaps-2-1-79-search__suggest-item_selected_yes {
    background: none;
  }

  .charging-car__input .ymaps-2-1-79-suggest-item-0 {
    margin-top: 0;
  }

  .map-legend__item-text {
    font-size: 21px;
    width: 218px;
    min-width: 218px;
  }

  .charging-car {
    padding-top: 93px;
  }

  .map-legend__item-text {
    font-size: 21px;
  }

  .charging-switches__item {
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }

  .charging-switches__item.active {
    padding-bottom: 3px;
  }

  .charging-switches__item + .charging-switches__item {
    margin-left: 32px;
  }

  .charging-car__input {
    margin-top: 30px;
  }

  .charging-temperature h3 {
    font-size: 16px;
    line-height: 100%;
  }

  .charging-temperature {
    width: 330px;
    margin-top: 26px;
  }

  .charging-temperature .temperature-points-wrap {
    height: 3px;
  }

  .charging-temperature #temperature-slider {
    height: 6px;
  }

  .temperature-points__item {
    width: 4px;
    height: 4px;
    top: 1px;
  }

  .charging-temperature #temperature-slider .noUi-handle {
    transform: scale(0.8);
  }

  .charging-temperature #temperature-slider .current-temp {
    transform: scale(1.2);
    margin-top: 15px;
  }

  .charging-temperature .noUi-origin {
    padding-left: 10px;
  }

  #temperature-slider .noUi-connects {
    background: linear-gradient(270deg, #60d2ba 56.07%, #d5001c 86.87%);
  }

  .map-legend__item-text {
    font-size: 18px;
    line-height: 150%;
    margin-left: 16px;
  }

  .map-legend__item-svg {
    height: 25px;
    min-width: 21px;
  }

  .map-legend__item-svg svg {
    height: 100%;
    width: 100%;
  }

  .map-legend__item + .map-legend__item {
    margin-left: 25px;
  }

  .map-legend {
    margin-top: 22px;
  }

  .charging-drop-arrow {
    margin-top: 20px;
  }

  .charging-drop-arrow svg {
    display: block;
    width: 64px;
    height: 28px;
  }

  .charging-counter {
    padding: 28px;
    max-width: 386px;
    bottom: 38px;
  }

  .charging-counter__title {
    font-size: 16px;
    line-height: 100%;
  }

  .charging-counter__num {
    font-size: 48px;
    line-height: 100%;
  }

  .PlugShare {
    max-width: 634px;
    padding: 32px 24px;
  }

  .PlugShare__title-img {
    width: 48px;
    height: 48px;
  }

  .PlugShare__title {
    font-size: 24px;
    line-height: 100%;
    margin-left: 12px;
  }

  .PlugShare__text {
    margin-top: 12px;
  }

  .PlugShare__text p {
    font-size: 11px;
    line-height: 150%;
  }

  .PlugShare__qrocde-wrap {
    width: 94px;
    height: 94px;
    min-width: 94px;
  }

  .PlugShare-switch__item {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 100%;
  }

  .PlugShare-switch__item + .PlugShare-switch__item {
    margin-left: 32px;
  }

  .PlugShare-descr p {
    font-size: 16px;
    line-height: 125%;
  }

  .PlugShare__right {
    padding-left: 22px;
  }

  .PlugShare-switch-wrap {
    margin-left: 26px;
  }

  .PlugShare-switch {
    padding-top: 3px;
  }

  .PlugShare-descr {
    margin-top: 15px;
  }
}

@media (max-width: 576px) {
  .PlugShare {
    max-width: 100%;
    flex-direction: column;
  }

  .PlugShare__info-wrap {
    width: 100%;
  }

  .PlugShare__right {
    width: 100%;
    margin-top: 30px;
    padding-left: 0;
  }
}
</style>